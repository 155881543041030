.homeimg {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-bottom: 5vh;
    max-width: 10000px;
    height: 50vh;
    filter: brightness(50%);
}

.centered {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.info {
    display: flex;
    justify-content: center;
    margin-bottom: 5vh;
}

.list {
    text-align: left;
    margin-right: 10vh;
}

.skyrimg {
    margin-right: 10vh;
}

.skyr {
    height: 10vh;
    width: 20vh;
}

